import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchIndexDocument } from 'commons';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  standalone: true,
  imports: [NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentListComponent {
  @Input()
  documents!: SearchIndexDocument[];

  @Output()
  editDocument = new EventEmitter<SearchIndexDocument>();

  constructor() {}

  edit(document: SearchIndexDocument) {
    this.editDocument.emit(document);
  }
}
