<app-container [sidebar]="true">
  <app-header-meta-action [title]="'Dokumente'">
    <button appTailwindButton *ngIf="isAccountant | async" (click)="fileInput.click()">
      Dokument hinzufügen
    </button>
  </app-header-meta-action>

  <app-sidebar-navigation>
    <app-sidebar-navigation-entry [routerLink]="'inbox'" *ngIf="!(isAccountant | async)"
      >Inbox
    </app-sidebar-navigation-entry>
    <app-sidebar-navigation-entry [routerLink]="'accounting'"
      >Zur Bearbeitung (Buchhaltung)
    </app-sidebar-navigation-entry>
    <app-sidebar-navigation-entry
      [routerLink]="'archive/' + archiveFolder.id"
      *ngFor="let archiveFolder of archiveFolder | async"
    >
      <div class="flex justify-between">
        <span class="truncate">{{ archiveFolder.name }}</span
        ><span class="font-normal text-xs">{{
          !(isAccountant | async) ? archiveFolder.documentCount : archiveFolder.sharedDocumentCount
        }}</span>
      </div>
    </app-sidebar-navigation-entry>
    <app-sidebar-navigation-entry [routerLink]="'search'"
    >Suche
    </app-sidebar-navigation-entry>
    <a href="/settings/document" class="flex text-xs text-gray-400 items-center justify-end"
      >Einstellungen</a
    >
  </app-sidebar-navigation>

  <router-outlet></router-outlet>
</app-container>

<input hidden type="file" #fileInput (change)="uploadFile($event)" />
