<app-document-card
  cardTitle="Search"
  *ngIf="documents$ | async as documents"
  [documents]="documents"
  [totalPages]="(totalPages$ | async) ?? 0"
  [currentPage]="(currentPage$ | async) ?? 1"
  [updateAccountingRelevantEnabled]="true"
  (searchValueChanged)="searchValue$.next($event)"
  (typeFilterChanged)="typeFilter$.next($event)"
  (pageChanged)="nextPage$.next($event)"
  [typeFilter]="typeFilter$ | async"
  (editDocument)="editDocuments($event)"
  (deleteDocument)="deleteDocuments($event)"
></app-document-card>
