import { Component, Inject } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  from,
  Observable,
  of,
  shareReplay,
  startWith,
  Subject,
  switchMap,
} from 'rxjs';
import { DocumentType, SearchIndexDocument } from 'commons';
import { DocumentService } from '../../../services/document.service';
import { DocumentCardComponent } from './document-card/document-card.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { Dialog } from '@angular/cdk/dialog';
import { DocumentSlideOverComponent } from '../document-slide-over/document-slide-over.component';
import { environment } from '../../../../environments/environment.local';
import { liteClient, SearchResponse } from 'algoliasearch/lite';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { SessionStateService } from '../../../services/session-state.service';
import { catchError, map, tap } from 'rxjs/operators';

interface SearchResult {
  documents: SearchIndexDocument[];
  currentPage: number;
  totalPages: number;
}

const EMPTY_RESULT: SearchResult = { documents: [], currentPage: -1, totalPages: 0 };

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  standalone: true,
  imports: [NgIf, DocumentCardComponent, AsyncPipe],
})
export class SearchComponent {
  documents$: Observable<SearchIndexDocument[]>;
  totalPages$: Observable<number>;
  currentPage$: Observable<number>;

  searchValue$ = new Subject<string | null>();
  nextPage$ = new Subject<number>();
  typeFilter$ = new BehaviorSubject<DocumentType[]>(['revenue', 'expense', 'other']);

  constructor(
    private documentService: DocumentService,
    private sessionStateService: SessionStateService,
    @Inject(Functions) private functions: Functions,
    @Inject(Dialog) private dialog: Dialog
  ) {
    let lastSearchValue: string | null = null;
    const documentSearchResult$: Observable<SearchResult> = combineLatest([
      this.searchValue$.pipe(startWith('')),
      this.nextPage$.pipe(startWith(0)),
    ]).pipe(
      tap(([searchValue, nextPage]) =>
        console.debug('Triggering document fetch with:', { searchValue, nextPage })
      ),
      switchMap(([searchValue, nextPage]) => {
        let pageToFetch = nextPage ?? 0;
        if (searchValue !== lastSearchValue) {
          lastSearchValue = searchValue;
          pageToFetch = 0;
        }

        return searchValue ? this.fetchDocuments(searchValue, pageToFetch) : of(EMPTY_RESULT);
      }),
      catchError((error) => {
        console.error('Error fetching documents:', error);
        return of(EMPTY_RESULT);
      }),
      shareReplay(1)
    );
    this.documents$ = documentSearchResult$.pipe(map((result) => result.documents));
    this.currentPage$ = documentSearchResult$.pipe(map((result) => result.currentPage));
    this.totalPages$ = documentSearchResult$.pipe(map((result) => result.totalPages));
  }

  editDocuments(selectedDocuments: SearchIndexDocument[]) {
    this.dialog.open(DocumentSlideOverComponent, { data: { documents: selectedDocuments } });
  }

  async deleteDocuments(selectedDocuments: SearchIndexDocument[]) {
    try {
      await Promise.all(
        selectedDocuments.map((document) =>
          this.documentService.deleteDocumentByPath(document.path)
        )
      );
      // TODO: Add user feedback for successful deletion
    } catch (error) {
      console.error('Error deleting documents:', error);
      // TODO: Add user feedback for error in deletion
    }
  }

  private fetchDocuments(searchValue: string, pageToFetch: number): Observable<SearchResult> {
    return from(this.searchDocumentIndex(searchValue, pageToFetch)).pipe(
      switchMap((searchDocumentIndexResult) => {
        const documents: SearchIndexDocument[] = searchDocumentIndexResult.hits.map((hit) => {
          return { ...hit, documentDate: hit.documentDate ?? null };
        });
        const currentPage: number = searchDocumentIndexResult.page ?? -1;
        const totalPages: number = searchDocumentIndexResult.nbPages ?? 0;
        return of({
          documents,
          currentPage,
          totalPages,
        });
      })
    );
  }

  private searchKey: string | null = null;
  private searchKeyTimestamp: number | null = null;
  private readonly SEARCH_KEY_EXPIRATION_TIME = 60 * 60 * 1000; // 1 hour in milliseconds

  private async getSearchKey(): Promise<string> {
    const currentTime = Date.now();

    if (
      this.searchKey &&
      this.searchKeyTimestamp &&
      currentTime - this.searchKeyTimestamp < this.SEARCH_KEY_EXPIRATION_TIME
    ) {
      return this.searchKey;
    }

    try {
      const orgaId = await firstValueFrom(this.sessionStateService.getOrgaId());
      const searchKeyResponse = await httpsCallable(this.functions, 'getSearchKey')({ orgaId });
      const { key } = searchKeyResponse.data as any;

      this.searchKey = key;
      this.searchKeyTimestamp = currentTime;

      return this.searchKey || '';
    } catch (error) {
      console.error('Error retrieving search key:', error);
      throw error;
    }
  }

  private async searchDocumentIndex(searchValue: string, page: number) {
    try {
      const key = await this.getSearchKey();
      const client = liteClient(environment.firebase.algoliaAppId, key);

      const searchParams = {
        indexName: 'jessieDocuments',
        query: searchValue,
        hitsPerPage: 20,
        page: page,
      };

      const response = await client.search({
        requests: [searchParams],
      });

      return response.results[0] as SearchResponse<any>;
    } catch (error) {
      console.error('Error during Algolia search:', error);
      throw error;
    }
  }
}
