import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  Observable,
  shareReplay,
  switchMap,
} from 'rxjs';
import { ArchiveFolder, DocumentType, SearchIndexDocument } from 'commons';
import { DocumentService, mapToSearchIndexDocuments } from '../../../services/document.service';
import { tap } from 'rxjs/operators';
import { DocumentCardComponent } from './document-card/document-card.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';
import { DocumentSlideOverComponent } from '../document-slide-over/document-slide-over.component';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  standalone: true,
  imports: [NgIf, DocumentCardComponent, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArchiveComponent {
  documents$!: Observable<SearchIndexDocument[]>;
  archive$!: Observable<ArchiveFolder>;

  searchValue = new BehaviorSubject<string | null>(null);
  limitRows = new BehaviorSubject<boolean>(true);
  typeFilter = new BehaviorSubject<DocumentType[]>(['revenue', 'expense', 'other']);

  constructor(
    private activatedRoute: ActivatedRoute,
    private documentService: DocumentService,
    private sessionState: SessionStateService,
    @Inject(Dialog) private dialog: Dialog
  ) {
    this.init();
  }

  async init() {
    const isAccountant = await firstValueFrom(
      this.sessionState.getOrgaUserRole().pipe(map((role) => role === 'accountant'))
    );
    const archiveFolderId$ = this.activatedRoute.params.pipe(
      map((params) => params.archiveFolderId)
    );

    this.documents$ = combineLatest([
      combineLatest([archiveFolderId$, this.limitRows, this.typeFilter]).pipe(
        switchMap(([folderId, limitRows, typeFilter]) =>
          this.documentService.getDocumentsInArchive(
            folderId,
            isAccountant,
            typeFilter,
            limitRows ? 10 : 0
          )
        )
      ),
      this.searchValue.pipe(
        tap((x) => {
          if (x) {
            this.limitRows.next(false);
          }
        })
      ),
    ]).pipe(
      map(([documents, searchValue]) => {
        return searchValue
          ? documents.filter((document) => {
              return (
                document.data.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
                (document.data.documentText
                  ? document.data.documentText.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
                  : false)
              );
            })
          : documents;
      }),
      map(mapToSearchIndexDocuments)
    );

    this.archive$ = combineLatest([this.documentService.getArchive(), archiveFolderId$]).pipe(
      map(([archive, archiveId]) => archive.data.folders[archiveId]),
      shareReplay(1)
    );
  }

  editDocument(documents: SearchIndexDocument[]) {
    this.dialog.open(DocumentSlideOverComponent, { data: { documents: documents } });
  }

  async deleteDocument(documents: SearchIndexDocument[]) {
    await Promise.all(
      documents.map((document) => this.documentService.deleteDocumentByPath(document.path))
    );
  }
}
