import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { firstValueFrom, map, Observable, Subscription } from 'rxjs';
import {
  ArchiveFolder,
  Category,
  Document,
  FirestoreCustomer,
  FirestoreDocument,
  FirestoreOrga,
  SearchIndexDocument,
} from 'commons';
import { CustomerService } from '../../../services/customer.service';
import { format, parseISO } from 'date-fns';
import { DocumentService } from '../../../services/document.service';
import { toLower } from 'lodash';
import { SlideOverFooterComponent } from '../../../components/slide-overs/slide-over/slide-over-footer/slide-over-footer.component';
import { HistoryComponent } from '../../history/history.component';
import { CommentSectionComponent } from '../../comments/comment-section/comment-section.component';
import { HideWhenNotInPlanDirective } from '../../../directives/hide-when-not-in-plan.directive';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { ToggleButtonComponent } from '../../../components/toggle-button-group/toggle-button/toggle-button.component';
import { ToggleButtonGroupComponent } from '../../../components/toggle-button-group/toggle-button-group.component';
import { InputComponent } from '../../../components/input/input.component';
import { TabOldDirective } from '../../../components/tabs/content-projected/tab.directive';
import { TabsComponent } from '../../../components/tabs/content-projected/tabs.component';
import { SlideOverContentComponent } from '../../../components/slide-overs/slide-over/slide-over-content/slide-over-content.component';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { SlideOverHeaderComponent } from '../../../components/slide-overs/slide-over/slide-over-header/slide-over-header.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CenterContentComponent } from '../../../components/slide-overs/slide-over/center-content/center-content.component';
import { SlideOverComponent } from '../../../components/slide-overs/slide-over/slide-over.component';
import { AsyncPipe, DatePipe, DecimalPipe, NgIf } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';
import { filter } from 'rxjs/operators';
import { HideWhenNotInRoleDirective } from '../../../directives/hide-when-not-in-role.directive';
import { NumberInputComponent } from '../../../components/input/number-input/number-input.component';
import { TextInputComponent } from '../../../components/input/text-input/text-input.component';
import { DateInputComponent } from '../../../components/input/date-input/date-input.component';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

export const expenseRevenueValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const type = control.get('type')?.value;
  const amount = control.get('amount')?.value;
  const valueDate = control.get('valueDate')?.value;

  return type !== 'other' && !(amount && valueDate) ? { expenseRevenueWrong: true } : null;
};

export interface DocumentSlideOverInput {
  documents: SearchIndexDocument[];
}

@Component({
  selector: 'app-document-slide-over',
  templateUrl: './document-slide-over.component.html',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    SlideOverComponent,
    CenterContentComponent,
    PdfViewerModule,
    SlideOverHeaderComponent,
    NgIconWrapperComponent,
    SlideOverContentComponent,
    TabsComponent,
    TabOldDirective,
    InputComponent,
    ToggleButtonGroupComponent,
    ToggleButtonComponent,
    TailwindInputDirective,
    NgSelectModule,
    TailwindButtonDirective,
    HideWhenNotInPlanDirective,
    CommentSectionComponent,
    HistoryComponent,
    SlideOverFooterComponent,
    AsyncPipe,
    HideWhenNotInRoleDirective,
    NumberInputComponent,
    TextInputComponent,
    DateInputComponent,
    DatePipe,
    DecimalPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentSlideOverComponent implements OnDestroy, OnInit {
  private subscription = new Subscription();
  title!: string;

  document!: FirestoreDocument;
  documentId!: string;
  downloadUrl!: string;
  documentForm!: FormGroup;

  archiveFolder$!: Observable<ArchiveFolder[]>;
  contentType?: string;
  customers$!: Observable<FirestoreCustomer[]>;

  categories!: Observable<Category[]>;

  numberOfDocuments = 0;
  currentDocumentIndex = 0;
  searchIndexDocuments: SearchIndexDocument[] = [];
  isInternalInvoice = false;
  showPaymentFields = false;
  isAiPanelOpen = false;

  recognizedCategoryNotFound = false;
  recognizedCompanyNotFound = false;

  constructor(
    private documentService: DocumentService,
    private customerService: CustomerService,
    private sessionState: SessionStateService,
    @Inject(DIALOG_DATA) public data: DocumentSlideOverInput,
    @Inject(DialogRef) private dialogRef: DialogRef,
    private cdr: ChangeDetectorRef
  ) {
    this.categories = this.sessionState.getOrga().pipe(
      filter((orga): orga is FirestoreOrga => !!orga),
      map((orga) => orga.data.categories ?? {}),
      map((categories) => Object.values(categories))
    );

    this.customers$ = this.customerService.getAllCustomers();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async ngOnInit() {
    this.archiveFolder$ = this.documentService.getArchive().pipe(
      map((archive) =>
        Object.values(archive.data.folders)
          .filter((folder) => folder.active)
          .sort(
            (a, b) => archive.data.folderSort.indexOf(a.id) - archive.data.folderSort.indexOf(b.id)
          )
      )
    );

    this.searchIndexDocuments = this.data.documents;
    this.numberOfDocuments = this.searchIndexDocuments.length;
    await this.initDocument(this.searchIndexDocuments[this.currentDocumentIndex]);
    this.cdr.markForCheck();
  }

  async initDocument(searchIndexDocument: SearchIndexDocument) {
    this.recognizedCompanyNotFound = false;
    this.documentId = searchIndexDocument.objectID;
    this.document = await firstValueFrom(
      this.documentService.getDocument(searchIndexDocument.objectID)
    );
    try {
      this.downloadUrl = await this.documentService.getDocumentUrl(this.document.data);
      this.contentType = (
        await this.documentService.getDocumentMetaData(this.document.data)
      ).contentType;
    } catch (e) {
      console.error('storage object not found', e);
    }

    await this.initForm();
  }

  async initForm() {
    const archiveFolders = await firstValueFrom(this.archiveFolder$);

    const doc = this.document.data;

    const customer = Object.values(doc.linkedEntities || {}).find(
      (linkEntity) =>
        linkEntity.entity === 'customer-individual' || linkEntity.entity === 'customer-company'
    )?.id;

    this.documentForm = new FormGroup({
      type: new FormControl(doc.type, []),
      name: new FormControl(doc.name, [Validators.required, Validators.maxLength(500)]),
      comment: new FormControl(doc.comment, [Validators.maxLength(1000)]),
      customer: new FormControl(customer),
      documentDate: new FormControl(
        doc.documentDate ? format(parseISO(doc.documentDate), 'yyyy-MM-dd') : '',
        [Validators.required, Validators.min(2000), Validators.max(2999)]
      ),
      accountRelevant: new FormControl(doc.accounting?.relevant, []),
      accountProcessed: new FormControl(doc.accounting?.processed, []),
      archived: new FormControl(doc.folder !== null, []),
      archiveFolder: new FormControl(doc.folder?.id ?? archiveFolders[0]?.id, []),
      amount: new FormControl('', [Validators.required]),
      valueDate: new FormControl('', [Validators.required]),
      financialYear: new FormControl('', [Validators.required]),
      paid: new FormControl('', []),
      category: new FormControl('', []),
    });

    if (doc.type === 'expense' || doc.type === 'revenue') {
      this.documentForm.patchValue({
        amount: doc.amountInCents ? (doc.amountInCents / 100).toString() : null,
        valueDate: doc.valueDate ? format(parseISO(doc.valueDate), 'yyyy-MM-dd') : null,
        financialYear: doc.financialYear ?? 2000,
        paid: doc.paid || false,
        category: doc.category || null,
      });
    }

    this.enableDisableFields();

    this.subscription.add(
      this.documentForm.get('type')?.valueChanges.subscribe(() => this.enableDisableFields())
    );
    this.documentForm
      .get('valueDate')
      ?.valueChanges.subscribe((value) =>
        this.documentForm.patchValue({ financialYear: new Date(value).getFullYear() })
      );

    const curatedValues = doc.documentAI?.curated;
    if (curatedValues && !doc.curatedValuesReviewed) {
      if (curatedValues.total_amount) {
        this.documentForm.patchValue({ type: 'expense', amount: curatedValues.total_amount });
      }
      if (curatedValues.due_date) {
        this.documentForm.patchValue({ type: 'expense', valueDate: curatedValues.due_date });
      }
      if (curatedValues.document_date) {
        this.documentForm.patchValue({ documentDate: curatedValues.document_date });
      }
      if (curatedValues.category) {
        const categories = await firstValueFrom(this.categories);
        const categoryName = toLower(curatedValues.category);
        const filteredCategory = categories.filter(
          (category) =>
            categoryName.includes(toLower(category.displayName)) ||
            toLower(category.displayName).includes(categoryName)
        );

        if (filteredCategory.length > 0) {
          this.documentForm.patchValue({ category: filteredCategory[0].displayName });
        } else {
          this.recognizedCategoryNotFound = true;
        }
      }
      if (curatedValues.supplier_name) {
        const customers = await firstValueFrom(this.customerService.getAllCustomers());
        const supplierName = toLower(curatedValues.supplier_name);
        const filteredCustomer = customers.filter(
          (customer) =>
            supplierName.includes(toLower(customer.data.displayName)) ||
            toLower(customer.data.displayName).includes(supplierName)
        );

        if (filteredCustomer.length > 0) {
          this.documentForm.patchValue({ customer: filteredCustomer[0].id });
        } else {
          this.recognizedCompanyNotFound = true;
        }
      }
    }
  }

  enableDisableFields() {
    if (this.documentForm.get('type')?.value === 'other') {
      this.documentForm.get('type')?.enable({ emitEvent: false });
      this.documentForm.get('amount')?.disable();
      this.documentForm.get('valueDate')?.disable();
      this.documentForm.get('financialYear')?.disable();
      this.documentForm.get('paid')?.disable();
      this.documentForm.get('category')?.disable();
      this.showPaymentFields = false;
      this.isInternalInvoice = false;
      this.documentForm.patchValue({ accountRelevant: this.document.data.accounting.relevant });
    } else {
      this.documentForm.get('type')?.enable({ emitEvent: false });
      this.documentForm.get('amount')?.enable();
      this.documentForm.get('valueDate')?.enable();
      this.documentForm.get('financialYear')?.enable();
      this.documentForm.get('paid')?.enable();
      this.documentForm.get('category')?.enable();
      this.documentForm.patchValue({ accountRelevant: true });

      this.showPaymentFields = true;
      this.isInternalInvoice = false;
    }
    if (this.document.data.type === 'revenue' && this.document.data.internalInvoice) {
      this.documentForm.get('type')?.disable({ emitEvent: false });
      this.documentForm.get('amount')?.disable();
      this.documentForm.get('valueDate')?.disable();
      this.documentForm.get('financialYear')?.disable();
      this.documentForm.get('paid')?.disable();
      this.documentForm.get('category')?.disable();
      this.documentForm.get('customer')?.disable();
      this.showPaymentFields = true;
      this.isInternalInvoice = true;
    }
  }

  async update() {
    this.documentForm.markAllAsTouched();
    if (!this.documentForm.valid) {
      return;
    }

    const value = this.documentForm.value;
    const archiveFolders = await firstValueFrom(this.archiveFolder$);
    //remove comments from updating
    const { comments, ...updateDocument }: Document = { ...this.document.data, type: value.type };

    if (
      !this.isInternalInvoice &&
      (updateDocument.type === 'expense' || updateDocument.type === 'revenue')
    ) {
      updateDocument.amountInCents = Math.round(value.amount * 100);
      updateDocument.valueDate = value.valueDate;
      updateDocument.financialYear = value.financialYear;
      updateDocument.paid = value.paid ?? false;
      updateDocument.category = value.category ?? null;
    }

    if (this.isInternalInvoice) {
      updateDocument.type = 'revenue';
    }

    const linkedEntities = Object.values(this.document.data.linkedEntities || {}).filter(
      (linkedEntity) =>
        linkedEntity.entity !== 'customer-company' && linkedEntity.entity !== 'customer-individual'
    );
    const customer = (await firstValueFrom(this.customers$)).find(
      (customer) => customer.id === value.customer
    );
    if (customer) {
      linkedEntities.push({
        entity: customer.data.type === 'company' ? 'customer-company' : 'customer-individual',
        id: customer.id,
        name: customer.data.displayName,
      });
    }

    this.documentService
      .updateDocument(this.documentId, {
        ...updateDocument,
        name: value.name,
        comment: value.comment,
        documentDate: value.documentDate,
        accounting: {
          relevant: !!value.accountRelevant,
          processed: !!value.accountRelevant && !!value.accountProcessed,
        },
        linkedEntities: linkedEntities.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}),

        folder: value.archived
          ? {
              entity: 'folder',
              id: value.archiveFolder,
              name: archiveFolders[archiveFolders.findIndex((x) => x.id === value.archiveFolder)]
                .name,
            }
          : null,
        curatedValuesReviewed: true,
      })
      .then(() => {
        if (this.currentDocumentIndex === this.numberOfDocuments - 1) {
          this.closeSlideOver();
        } else {
          this.currentDocumentIndex++;
          this.initDocument(this.searchIndexDocuments[this.currentDocumentIndex]);
        }
      });
  }

  async createCustomer(customerName: string) {
    const docRef = await this.customerService.createCustomer({
      type: 'company',
      displayName: customerName,
      company: {
        displayName: customerName,
        legalName: customerName,
      },
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        countryCode: 'CH',
        zipCode: '',
      },
    });
    const newCustomer = await firstValueFrom(this.customerService.getCustomer(docRef.id));
    this.documentForm.patchValue({ customer: newCustomer.id });

    this.recognizedCompanyNotFound = false;
  }

  closeSlideOver() {
    this.dialogRef.close();
  }

  deleteDocument() {
    this.documentService.deleteDocument(this.document);
    this.closeSlideOver();
  }

  downloadDocument() {
    this.documentService.downloadDocument(this.document);
  }

  hasAiSuggestions(): boolean {
    const ai = this.document.data.documentAI?.raw;
    return !!(
      ai?.document_name ||
      ai?.document_description ||
      ai?.document_date ||
      ai?.total_amount ||
      ai?.due_date ||
      ai?.supplier_name
    );
  }

  applyAiSuggestion(field: string, value: any) {
    this.documentForm.patchValue({ [field]: value });
  }
}
